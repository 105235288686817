import axios from "axios";

const baseURL = `${process.env.API_URL}/api/v1/`;

const authInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export { authInstance };
