import axiosInstance from "@utils/axios";
import { Game, GameMove } from "@utils/types";
import {
    CreateGameInput,
    CreateGameMoveInput,
    JoinGameInput,
    LaunchGameInput,
    PatchGameConnectionInput,
} from "./types";

const createGame = async (data: CreateGameInput) => {
    const response = await axiosInstance.post("games/", data);
    return response.data as Game;
};

const joinGame = async (data: JoinGameInput) => {
    const response = await axiosInstance.post(`games/${data.code}/connections/`);
    return response.data as Game;
};

const getGame = async (code: string) => {
    const response = await axiosInstance.get(`games/${code}/`);
    return response.data as Game;
};

const patchGameConnection = async (code: string, data: PatchGameConnectionInput) => {
    const response = await axiosInstance.patch(`games/${code}/connections/`, data);
    return response.data as Game;
};

const launchGame = async (code: string, data: LaunchGameInput) => {
    const response = await axiosInstance.patch(`games/${code}/`, data);
    return response.data as Game;
};

const createGameMove = async (code: string, data: CreateGameMoveInput) => {
    const response = await axiosInstance.post(`games/${code}/moves/`, data);
    return response.data as GameMove;
};

const gameWebSockets = (group: string) => {
    return new WebSocket(`${process.env.SOCKET_URL}/ws/games/${group}/`);
};

export {
    createGame,
    createGameMove,
    joinGame,
    launchGame,
    getGame,
    patchGameConnection,
    gameWebSockets,
};
