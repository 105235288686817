import React from "react";
import styles from "./css/DrinksInput.module.css";
import Button from "@components/buttons";
import { GameConnection } from "@utils/types";
import { useSelf } from "@utils/api";

interface Props {
    selectedGameConnection: GameConnection | undefined;
    setSelectedGameConnection: React.Dispatch<React.SetStateAction<GameConnection | undefined>>;
    gameConnections: GameConnection[];
}

const DrinksInput: React.FC<Props> = props => {
    const { data: user } = useSelf();
    const handleOnClick = (value: GameConnection) => {
        props.setSelectedGameConnection(value);
    };

    return (
        <span className={styles.row}>
            {props.gameConnections
                .filter(gameConnection => gameConnection.user.username !== user?.username)
                .map(gameConnection => (
                    <Button
                        key={gameConnection.user.username}
                        name={gameConnection.user.username}
                        className={`${
                            props.selectedGameConnection?.user.username ===
                            gameConnection.user.username
                                ? ` ${styles.selected}`
                                : ``
                        }`}
                        onClick={() => handleOnClick(gameConnection)}
                    >
                        {gameConnection.user.username}
                    </Button>
                ))}
        </span>
    );
};

export default DrinksInput;
