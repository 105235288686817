import React, { useCallback, useMemo } from "react";
import styles from "./css/Lobby.module.css";
import { useGetReadyOnGame, useLaunchGame, useLeaveGame } from "@utils/api";
import { Game, User } from "@utils/types";
import Text, { Heading } from "@components/texts";
import Button from "@components/buttons";
import { Main } from "@components/layout";
import Table from "@components/table";
import { useIntl } from "react-intl";

interface Props {
    game: Game;
    user: User | undefined;
}

const Lobby: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;
    const getReady = useGetReadyOnGame(props.game.code);
    const leaveGame = useLeaveGame(props.game.code);
    const launchGame = useLaunchGame(props.game.code);

    const isHost = useMemo(() => props.user?.username === props.game.host.username, [props]);
    const allUsersReady = useMemo(
        () => props.game.connections.every(connection => connection.ready),
        [props.game]
    );
    const minimumNumberOfUsers = useMemo(() => {
        return props.game.connections.length >= 2;
    }, [props.game]);

    /* istanbul ignore next */
    const isReady = useMemo(() => {
        const { user } = props;
        if (!user || props.game.connections.length < 1) return false;
        const connection = props.game.connections.find(
            connection => connection.user.username === user.username
        );
        return connection?.ready || false;
    }, [props]);

    /* istanbul ignore next */
    const statusButtonText = useMemo(() => {
        if (isReady) return translate({ id: "game.lobby.unready" });
        return translate({ id: "game.lobby.get-ready" });
    }, [isReady, translate]);

    /* istanbul ignore next */
    const handleReadyStatus = useCallback(() => {
        if (getReady.isLoading || leaveGame.isLoading) return;
        if (isReady) return leaveGame.mutate();
        getReady.mutate();
    }, [isReady, getReady, leaveGame]);

    /* istanbul ignore next */
    const handleLaunchGame = useCallback(() => {
        if (launchGame.isLoading) return;
        launchGame.mutate();
    }, [launchGame]);

    return (
        <Main>
            <header className={styles.header}>
                <Heading>{props.game.name}</Heading>
                <Text>
                    <span className={styles.bold}>
                        {translate({ id: "game.lobby.game-code" })}:
                    </span>{" "}
                    {props.game.code}
                </Text>
                <Text>
                    <span className={styles.bold}>
                        {translate({ id: "game.lobby.hosted-by" })}:
                    </span>{" "}
                    {props.game.host.username}
                </Text>
            </header>
            <Table
                head={[
                    translate({ id: "game.lobby.table.username" }),
                    translate({ id: "game.lobby.table.active" }),
                ]}
                body={props.game.connections.map(connection => ({
                    cells: [
                        { display: connection.user.username, fullWidth: true },
                        { display: connection.ready, centered: true },
                    ],
                }))}
            />
            <footer className={styles.footer}>
                <Button onClick={handleReadyStatus} name="getReady" submitting={getReady.isLoading}>
                    {statusButtonText}
                </Button>
                {isHost && minimumNumberOfUsers && allUsersReady ? (
                    <Button
                        onClick={handleLaunchGame}
                        name="launchGame"
                        submitting={launchGame.isLoading}
                    >
                        {translate({ id: "game.lobby.start-game" })}
                    </Button>
                ) : undefined}
            </footer>
        </Main>
    );
};

export default Lobby;
