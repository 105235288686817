import React from "react";
import { GuessInput } from "../input";
import { GuessColor } from "../consts";
import SharedProps from "./props";
import { useIntl } from "react-intl";

const FirstGuess: React.FC<SharedProps> = props => {
    const translate = useIntl().formatMessage;

    return (
        <React.Fragment>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessColor.RED}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.first-guess.red" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessColor.BLACK}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.first-guess.black" })}
            </GuessInput>
        </React.Fragment>
    );
};

export { FirstGuess };
