import React, { useMemo } from "react";
import { Oval } from "react-loader-spinner";
import styles from "./css/Spinner.module.css";

interface Props {
    width?: string;
    height?: string;
    absolute?: boolean;
    centered?: boolean;
}

const Spinner: React.FC<Props> = props => {
    const width = useMemo(() => (props.width ? props.width : "1.5rem"), [props.width]);
    const height = useMemo(() => (props.height ? props.height : "1.5rem"), [props.height]);

    return (
        <Oval
            width={width}
            height={height}
            strokeWidth={8}
            wrapperClass={`${styles.spinner}${props.absolute ? ` ${styles.absolute}` : ``}${
                props.centered ? ` ${styles.centered}` : ``
            }`}
            color="var(--text-color)"
            secondaryColor="var(--text-color)"
        />
    );
};

export { Spinner };
