/* istanbul ignore file */
import { createContext } from "react";
import { LanguageMap, RecognizedLanguage } from "./LanguageMap";
import { DEFAULT_LANGUAGE } from "./consts";

interface Context {
    locale: keyof typeof LanguageMap;
    setLocale: (targetTheme: RecognizedLanguage) => void;
}

const LanguageContext = createContext<Context>({
    locale: DEFAULT_LANGUAGE,
    setLocale: (locale: RecognizedLanguage) => {},
});

export { LanguageContext };
