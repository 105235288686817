import React, { useContext, useState } from "react";
import styles from "./css/LanguageSelector.module.css";
import sharedStyles from "../css/Shared.module.css";
import Button from "@components/buttons";
import { LanguageContext } from "@utils/providers/languages/context";
import LanguagePopup from "./popup";
import { LanguageMap } from "@utils/providers";
import { LanguageSelectOption } from "./popup/types";

const LanguageSelector: React.FC = () => {
    const { locale, setLocale } = useContext(LanguageContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsPopupVisible(false);
    };

    return (
        <span className={styles.language_selector}>
            <Button
                className={sharedStyles.shared}
                name="languageSelector"
                onClick={() => setIsPopupVisible(prev => !prev)}
            >
                {LanguageMap[locale].displayName}
            </Button>
            {isPopupVisible && (
                <React.Fragment>
                    <div className={styles.backdrop} onClick={event => handleClick(event)} />
                    <LanguagePopup
                        options={Object.entries(LanguageMap).map(
                            ([key, value]) =>
                                ({
                                    locale: key,
                                    displayName: value.displayName,
                                }) as LanguageSelectOption
                        )}
                        language={locale}
                        setLanguage={setLocale}
                    />
                </React.Fragment>
            )}
        </span>
    );
};

export default LanguageSelector;
