import cz from "@localization/languages/cz.json";
import en from "@localization/languages/en.json";
import fr from "@localization/languages/fr.json";

type LanguageSource = { [key: string]: string };

interface LanguageOption {
    readonly source: LanguageSource;
    readonly displayName: string;
}

const LanguageMap = {
    cs: { source: cz, displayName: "Česky" } as LanguageOption,
    "en-US": { source: en, displayName: "English" } as LanguageOption,
    fr: { source: fr, displayName: "Français" } as LanguageOption,
} as const;

type RecognizedLanguage = keyof typeof LanguageMap;

export { LanguageMap, RecognizedLanguage };
