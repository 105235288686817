/* istanbul ignore file */
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useGame, useGameWebSockets, useLeaveGame, useSelf } from "@utils/api";
import { useOnBeforeUnload } from "@utils/hooks";
import { GameStatus, GameSocketUpdate, GameMove } from "@utils/types";
import Text from "@components/texts";
import { Spinner } from "@components/spinner";
import Lobby from "./lobby";
import RunningGame from "./running";
import { toast } from "react-toastify";
import { InfoIcon } from "@components/icons";

const Game: React.FC = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    const { code } = useParams();
    const { data: user } = useSelf();
    const { data: game, isLoading: gameLoading, error: gameError } = useGame(code || "");
    const leaveGame = useLeaveGame(game?.code || "");

    const handleGameUpdateToast = useCallback(
        (move?: GameMove) => {
            if (!move || !user || move.game_connection.user.username === user.username) return;
            toast.info(
                `${move.game_connection.user.username} ${translate({
                    id: "common.guessed",
                })} '${move.user_action.toLocaleLowerCase()}': ${move.outcome.toLocaleLowerCase()}`,
                { icon: InfoIcon }
            );
        },
        [user, translate]
    );

    const handleGameUpdate = useCallback(
        (update: GameSocketUpdate) => {
            queryClient.setQueryData(["games", code], update.game);
            handleGameUpdateToast(update.move);
        },
        [queryClient, code, handleGameUpdateToast]
    );

    useGameWebSockets(code, handleGameUpdate);
    useOnBeforeUnload(() => {
        if (leaveGame.isLoading) return;
        leaveGame.mutate();
    });

    const view = useMemo(() => {
        if (!game && gameLoading) return <Spinner centered width="2rem" height="2rem" />;
        if ((!game && !gameLoading) || gameError)
            return (
                <Text>
                    {translate({ id: "common.error" })}:{" "}
                    {gameError
                        ? gameError.message
                        : translate({ id: "game.error.generic.failed-to-fetch" })}
                </Text>
            );
        if (game.status === GameStatus.CREATED) return <Lobby game={game} user={user} />;
        if (game.status === GameStatus.RUNNING)
            return <RunningGame game={game} loadingGame={gameLoading} />;
        return <React.Fragment />;
    }, [game, user, gameError, gameLoading, translate]);

    return view;
};

export default Game;
