import React from "react";
import { AceValue as AceValueType } from "@utils/types";
import { AceValueInput } from "./AceValueInput";
interface Props {
    selectedValue: AceValueType | undefined;
    setSelectedValue: (value: AceValueType | undefined) => void;
}

const AceValue: React.FC<Props> = props => {
    return <AceValueInput selected={props.selectedValue} setSelected={props.setSelectedValue} />;
};

export default AceValue;
