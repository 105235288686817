import React from "react";
import styles from "./css/Main.module.css";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Main: React.FC<Props> = props => {
    return (
        <main className={`${styles.main}${props.className ? ` ${props.className}` : ``}`}>
            {props.children}
        </main>
    );
};

export { Main };
