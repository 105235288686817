import axiosInstance, { authInstance } from "@utils/axios";
import { BlocklistedTokenResponseType, TokenResponseType } from "@utils/types";
import { LoginInput } from "./types";

const login = async (data: LoginInput) => {
    const response = await authInstance.post("auth/", data);
    return response.data as TokenResponseType;
};

const logout = async () => {
    const response = await axiosInstance.delete("auth/");
    return response.data as BlocklistedTokenResponseType;
};

export { login, logout };
