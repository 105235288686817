import React from "react";
import styles from "./css/Heading.module.css";
import sharedStyles from "../css/Shared.module.css";
import SharedProps from "../props";

const Heading: React.FC<SharedProps> = props => {
    return (
        <h1
            className={`${styles.heading} ${sharedStyles.shared}${
                props.className ? ` ${props.className}` : ``
            }`}
            style={props.style}
        >
            {props.children}
        </h1>
    );
};

export { Heading };
