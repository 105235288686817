import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CreateUserInput } from "@utils/api";
import { toast } from "react-toastify";
import Form, { Fieldset } from "@components/forms";
import TextInput, { PasswordInput, Checkbox } from "@components/inputs";
import { SubmitButton } from "@components/buttons";
import Text, { Link } from "@components/texts";
import { ErrorIcon } from "@components/icons";
import { SharedProps } from "../../props";

interface Props extends SharedProps<CreateUserInput> {}

const RegisterForm: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    const onSubmit = () => {
        if (confirmPassword !== password) {
            toast.error(translate({ id: "auth.error.password.no-match" }), { icon: ErrorIcon });
            return;
        }
        const data = {
            username: username,
            password: password,
            remember: remember,
        };
        props.onSubmit(data);
    };

    return (
        <Form form={props.form} className={props.className} onSubmit={onSubmit}>
            <Fieldset form={props.form}>
                <TextInput
                    value={username}
                    setValue={setUsername}
                    form={props.form}
                    name="username"
                    placeholder={translate({ id: "auth.username" })}
                    autoComplete="username"
                    maxLength={16}
                    autoFocus
                    required
                />
                <PasswordInput
                    value={password}
                    setValue={setPassword}
                    form={props.form}
                    name="password"
                    placeholder={translate({ id: "auth.password" })}
                    autoComplete="new-password"
                    required
                />
                <PasswordInput
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    form={props.form}
                    name="confirmPassword"
                    placeholder={translate({ id: "auth.confirm-password" })}
                    autoComplete="new-password"
                    required
                />
                <Checkbox value={remember} setValue={setRemember} form={props.form} name={""}>
                    {translate({ id: "auth.remember-login" })}
                </Checkbox>
            </Fieldset>
            <SubmitButton form={props.form} name="submitRegister" submitting={props.submitting}>
                {translate({ id: "common.register" })}
            </SubmitButton>
            <Text>
                {translate({ id: "auth.register.prompt.no-account-yet" })}?{" "}
                <Link to="/login">{translate({ id: "common.login" })}</Link>
            </Text>
        </Form>
    );
};

export { RegisterForm };
