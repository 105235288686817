import React, { useCallback, useMemo } from "react";
import { Card } from "@utils/types";
import { GuessInputType } from "./consts";
import { FirstGuess, FourthGuess, SecondGuess, ThirdGuess } from "./guesses";

interface Props {
    cards: Card[];
    currentGuess?: GuessInputType;
    isUsersRound: boolean;
    setGuess: (guess: GuessInputType) => void;
    form: string;
}

const GuessingInput: React.FC<Props> = props => {
    const getFirstHiddenCardIndex = useCallback((cards: Card[]) => {
        return cards.findIndex(card => card.visible === false);
    }, []);

    const inputs: { [key: number]: React.ReactElement } = useMemo(
        () => ({
            0: (
                <FirstGuess
                    currentGuess={props.currentGuess}
                    setGuess={props.setGuess}
                    isDisabled={!props.isUsersRound}
                    form={props.form}
                />
            ),
            1: (
                <SecondGuess
                    currentGuess={props.currentGuess}
                    setGuess={props.setGuess}
                    isDisabled={!props.isUsersRound}
                    form={props.form}
                />
            ),
            2: (
                <ThirdGuess
                    currentGuess={props.currentGuess}
                    setGuess={props.setGuess}
                    isDisabled={!props.isUsersRound}
                    form={props.form}
                />
            ),
            3: (
                <FourthGuess
                    currentGuess={props.currentGuess}
                    setGuess={props.setGuess}
                    isDisabled={!props.isUsersRound}
                    form={props.form}
                />
            ),
        }),
        [props.currentGuess, props.isUsersRound, props.form, props.setGuess]
    );

    const stage = useMemo(() => {
        const firstHiddenIndex = getFirstHiddenCardIndex(props.cards);
        if (firstHiddenIndex < 0) return undefined;
        return inputs[firstHiddenIndex];
    }, [props.cards, getFirstHiddenCardIndex, inputs]);

    if (!stage || !props.isUsersRound) {
        <React.Fragment />;
    }

    return stage;
};

export { GuessingInput };
