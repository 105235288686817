import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "../theme";

const ToastProvider: React.FC = () => {
    const autoClose = 2500;
    const { theme } = useContext(ThemeContext);

    return (
        <ToastContainer
            position="top-center"
            autoClose={autoClose}
            hideProgressBar={true}
            closeButton={false}
            limit={3}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            closeOnClick
            draggable
            theme={theme}
        />
    );
};

export { ToastProvider };
