import React, { ReactNode } from "react";
import styles from "./css/Fieldset.module.css";

interface Props {
    form: string;
    children: ReactNode;
    className?: string;
}

const Fieldset: React.FC<Props> = props => {
    return (
        <fieldset
            form={props.form}
            className={`${styles.fieldset}${props.className ? ` ${props.className}` : ``}`}
        >
            {props.children}
        </fieldset>
    );
};

export { Fieldset };
