import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useSelf } from "@utils/api";

import HomeAuthenticated from "@views/home/authenticated";
import HomeUnauthenticated from "@views/home/unauthenticated";
import Login from "@views/auth/login";
import Register from "@views/auth/register";
import CreateGame from "@views/games/create";
import JoinGame from "@views/games/join";
import Game from "@views/games/game";
import Layout from "@components/layout";
import { Spinner } from "@components/spinner";

const App: React.FC = () => {
    const translate = useIntl().formatMessage;
    const { data: user, isLoading: userLoading } = useSelf();

    useEffect(() => {
        document.title = translate({ id: "common.autobus" });
    }, [translate]);

    if (!user && userLoading) {
        return <Spinner centered width="2rem" height="2rem" />;
    }

    return (
        <Router>
            <Layout>
                <Routes>
                    {!user ? (
                        <React.Fragment>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="*" element={<HomeUnauthenticated />} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Route path="/game/create" element={<CreateGame />} />
                            <Route path="/game/join" element={<JoinGame />} />
                            <Route path="/game/:code" element={<Game />} />
                            <Route path="*" element={<HomeAuthenticated />} />
                        </React.Fragment>
                    )}
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;
