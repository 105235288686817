import React from "react";
import { useIntl } from "react-intl";
import { GuessInput } from "../input";
import { GuessValue } from "../consts";
import SharedProps from "./props";

const SecondGuess: React.FC<SharedProps> = props => {
    const translate = useIntl().formatMessage;

    return (
        <React.Fragment>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessValue.SMALLER}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.second-guess.smaller" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessValue.LARGER}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.second-guess.larger" })}
            </GuessInput>
        </React.Fragment>
    );
};

export { SecondGuess };
