import React from "react";
import { faBeer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./css/Drink.module.css";

const Drink: React.FC = () => {
    return <FontAwesomeIcon icon={faBeer} className={styles.drink} />;
};

export { Drink };
