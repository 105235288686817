import React from "react";
import Button from "../button";
import SharedProps from "../props";

interface Props extends SharedProps {
    form: string;
}

const SubmitButton: React.FC<Props> = props => {
    return <Button {...props} form={props.form} type="submit" />;
};

export { SubmitButton };
