import React, { useMemo } from "react";
import styles from "./css/Game.module.css";
import { Game } from "@utils/types";
import { useSelf } from "@utils/api";
import { Main } from "@components/layout";
import Players from "./players";
import GameTable from "./table";
import GameInputForm from "./inputs";

interface Props {
    game: Game;
    loadingGame: boolean;
}

const RunningGame: React.FC<Props> = props => {
    const { data: user, isLoading: loadingUser } = useSelf();

    const playerConnections = useMemo(
        () =>
            props.game.connections.filter(
                connection => connection.user.username !== user?.username
            ),
        [props.game.connections, user?.username]
    );

    const selfConnection = useMemo(
        () =>
            props.game.connections.find(connection => connection.user.username === user?.username),
        [props.game.connections, user?.username]
    );

    return (
        <Main className={styles.game}>
            <Players gameConnections={playerConnections} loading={props.loadingGame} />
            <GameTable table={props.game.table} loading={props.loadingGame} />
            <GameInputForm
                gameConnection={selfConnection}
                loading={loadingUser}
                game={props.game}
            />
        </Main>
    );
};

export default RunningGame;
