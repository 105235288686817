import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { JoinGameInput, useJoinGame } from "@utils/api";
import { JoinGameForm } from "@components/forms";
import { Main } from "@components/layout";
import { Heading } from "@components/texts";

const JoinGame: React.FC = () => {
    const form = "join-game-form";
    const translate = useIntl().formatMessage;
    const navigate = useNavigate();

    const joinGameMutation = useJoinGame();

    /* istanbul ignore next */
    const onSubmit = (data: JoinGameInput) => {
        if (joinGameMutation.isLoading) return;
        joinGameMutation.mutate(data, {
            onSuccess: response => {
                navigate(`/game/${response.code}`);
            },
        });
    };

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.join-game" })}</Heading>
            </header>
            <JoinGameForm form={form} onSubmit={onSubmit} submitting={joinGameMutation.isLoading} />
        </Main>
    );
};

export default JoinGame;
