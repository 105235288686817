import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./css/AceValueRow.module.css";
import Button from "@components/buttons";
import Text from "@components/texts";
import { AceValue } from "@utils/types";
import { AceValueInput } from "./AceValueInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";

interface Props {
    aceValue: AceValue | undefined;
    selectedValue: AceValue | undefined;
    setSelectedValue: (value: AceValue | undefined) => void;
}

const AceValueRow: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;

    const aceValue = useMemo(() => {
        if (!props.aceValue)
            return (
                <AceValueInput
                    selected={props.selectedValue}
                    setSelected={props.setSelectedValue}
                    small
                />
            );
        if (props.aceValue === AceValue.LOWEST) {
            return (
                <Text>{translate({ id: "game.input.ace.value.lowest" }).toLocaleLowerCase()}</Text>
            );
        }
        if (props.aceValue === AceValue.HIGHEST) {
            return (
                <Text>{translate({ id: "game.input.ace.value.highest" }).toLocaleLowerCase()}</Text>
            );
        }
        return <Text>{translate({ id: "game.input.ace.value.highest" }).toLocaleLowerCase()}</Text>;
    }, [props.aceValue, props.selectedValue, props.setSelectedValue, translate]);

    const clearSelected = useCallback(() => {
        props.setSelectedValue(undefined);
    }, [props]);

    useEffect(() => {
        if (!props.selectedValue) return;
        if (props.aceValue) props.setSelectedValue(undefined);
    }, [props]);

    return (
        <span className={styles.ace_row}>
            <Text>{translate({ id: "game.input.ace-value" })}: </Text>
            {aceValue}
            {props.selectedValue && !props.aceValue ? (
                <Button
                    className={styles.button_clear}
                    name={"clearSelection"}
                    onClick={clearSelected}
                >
                    {
                        <FontAwesomeIcon
                            data-testid="connectionStatus"
                            className={styles.icon_clear}
                            icon={faXmark}
                        />
                    }
                </Button>
            ) : undefined}
        </span>
    );
};

export { AceValueRow };
