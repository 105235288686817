import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faCircleInfo, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const InfoIcon: React.FC = () => (
    <FontAwesomeIcon
        fontSize="var(--font-size-medium)"
        color="var(--accent-blue)"
        icon={faCircleInfo}
    />
);

const ErrorIcon: React.FC = () => (
    <FontAwesomeIcon
        fontSize="var(--font-size-medium)"
        color="var(--accent-red)"
        icon={faCircleXmark}
    />
);

const SuccessIcon: React.FC = () => (
    <FontAwesomeIcon
        fontSize="var(--font-size-medium)"
        color="var(--accent-green)"
        icon={faCheckCircle}
    />
);

const WarningIcon: React.FC = () => (
    <FontAwesomeIcon
        fontSize="var(--font-size-medium)x"
        color="var(--accent-orange)"
        icon={faTriangleExclamation}
    />
);

export { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon };
