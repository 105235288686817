import React from "react";
import { GameConnection } from "@utils/types";
import Text from "@components/texts/text";
import { Drink } from "./Drink";
import styles from "./css/Drinks.module.css";

interface Props {
    gameConnection: GameConnection;
}

const Drinks: React.FC<Props> = props => {
    if (!props.gameConnection.drinks_received_amount) {
        <span className={styles.drinks}>
            <Text>No drinks received</Text>
        </span>;
    }

    return (
        <span className={styles.drinks}>
            <Text>
                {props.gameConnection.drinks_received_amount} x <Drink />
            </Text>
        </span>
    );
};

export { Drinks };
