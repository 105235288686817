import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styles from "./css/Layout.module.css";
import ArrowBack from "../arrow";
import VersionInfo from "../version";
import ThemeToggle from "../theme";
import LanguageSelector from "../language";
import getBackwardNavigationForRoute from "./backwardNavigationMap";

interface Props {
    children: React.ReactNode;
}

const Layout: React.FC<Props> = props => {
    const location = useLocation();
    const routeBack = useMemo(() => {
        return getBackwardNavigationForRoute(location.pathname);
    }, [location]);

    return (
        <div className={styles.layout}>
            {props.children}
            <span className={`${styles.container} ${styles.top_left}`}>
                {routeBack && <ArrowBack routeBack={routeBack} />}
            </span>
            <span className={`${styles.container} ${styles.bottom_left}`}>
                <VersionInfo />
            </span>
            <span className={`${styles.container} ${styles.bottom_right}`}>
                <LanguageSelector />
                <ThemeToggle />
            </span>
        </div>
    );
};

export default Layout;
