import { Card } from "./card";
import { Table } from "./table";
import { User } from "./users";

enum GameStatus {
    CREATED = "CREATED",
    RUNNING = "RUNNING",
    ENDED = "ENDED",
}

enum GameStage {
    GUESSING_GAME = "GUESSING_GAME",
    PYRAMID = "PYRAMID",
    ELIMINATION_GAME = "ELIMINATION_GAME",
    AUTOBUS = "AUTOBUS",
}

enum MoveOutcome {
    CORRECT = "CORRECT",
    INCORRECT = "INCORRECT",
    NEUTRAL = "NEUTRAL",
}

enum AceValue {
    LOWEST = "LOWEST",
    HIGHEST = "HIGHEST",
}

interface Game {
    name: string;
    code: string;
    status: GameStatus;
    stage?: GameStage;
    host: User;
    current_connection_turn?: GameConnection;
    connections: GameConnection[];
    table?: Table;
}

interface GameConnection {
    user: User;
    ready: boolean;
    cards: Card[];
    ace_value?: AceValue;
    drinks_received_amount: number;
    drinks_given_amount: number;
}

interface GameConnectionLight {
    user: User;
    ready: boolean;
}

interface GameMove {
    stage: GameStage;
    task: string;
    user_action: string;
    game_connection: GameConnectionLight;
    outcome: MoveOutcome;
}

interface GameSocketUpdate {
    game: Game;
    move?: GameMove;
}

export {
    AceValue,
    Game,
    GameConnection,
    GameConnectionLight,
    GameMove,
    GameStage,
    GameStatus,
    GameSocketUpdate,
    MoveOutcome,
};
