import React, { useCallback, useMemo } from "react";
import styles from "./css/GuessInput.module.css";
import Button from "@components/buttons";
import { GuessInputType } from "../consts";

interface Props {
    value: GuessInputType;
    currentGuess?: GuessInputType;
    handleClick: (value: GuessInputType) => void;
    children: React.ReactNode;
    isDisabled?: boolean;
    form: string;
}

const GuessInput: React.FC<Props> = props => {
    const handleClick = useCallback(() => props.handleClick(props.value), [props]);

    const isSelected = useMemo(
        () => props.value === props.currentGuess,
        [props.value, props.currentGuess]
    );

    return (
        <li className={styles.guess} data-testid="guess-option">
            <Button
                onClick={handleClick}
                focused={isSelected}
                className={styles.button}
                disabled={props.isDisabled}
                name="guess"
                form={props.form}
            >
                {props.children}
            </Button>
        </li>
    );
};

export { GuessInput };
