import React from "react";
import { GuessInput } from "../input";
import { CardSuit } from "@utils/types";
import SharedProps from "./props";
import { useIntl } from "react-intl";

const FourthGuess: React.FC<SharedProps> = props => {
    const translate = useIntl().formatMessage;

    return (
        <React.Fragment>
            <GuessInput
                handleClick={props.setGuess}
                value={CardSuit.HEARTS}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "common.suit.hearts" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={CardSuit.DIAMONDS}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "common.suit.diamonds" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={CardSuit.SPADES}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "common.suit.spades" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={CardSuit.CLUBS}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "common.suit.clubs" })}
            </GuessInput>
        </React.Fragment>
    );
};

export { FourthGuess };
