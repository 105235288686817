import axios from "axios";

const baseURL = `${process.env.API_URL}/api/v1/`;

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    config => config,
    error => {
        if (error.response && error.response.status === 401)
            return (window.location.href = "/logout");
        return error;
    }
);

export default axiosInstance;
