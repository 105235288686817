import React, { useMemo, useState } from "react";
import styles from "./css/TextInput.module.css";
import SharedProps from "../props";

type InputMode = "email" | "search" | "tel" | "text" | "url" | "numeric" | "none" | "decimal";

interface Props extends SharedProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: React.HTMLInputTypeAttribute;
    inputMode?: InputMode;
}

const TextInput: React.FC<Props> = props => {
    const [focused, setFocused] = useState(false);
    const focusedOrFilled = useMemo(() => focused || props.value !== "", [props.value, focused]);

    const wrapperClassName = useMemo(() => {
        return `${styles.wrapper}${props.className ? ` ${props.className}` : ``}${
            focusedOrFilled ? ` ${styles.focused}` : ``
        }${props.warning ? ` ${styles.warning}` : ``}`;
    }, [focusedOrFilled, props.className, props.warning]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) return props.onChange(event);
        props.setValue(event.target.value);
    };

    return (
        <span className={wrapperClassName}>
            <input
                value={props.value}
                onChange={handleChange}
                type={props.type || "text"}
                inputMode={props.inputMode}
                form={props.form}
                name={props.name}
                placeholder={props.placeholder}
                className={`${styles.input}${
                    props.innerClassName ? ` ${props.innerClassName}` : ``
                }`}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                minLength={props.minLength}
                maxLength={props.maxLength || 128}
                tabIndex={0}
                autoComplete={props.autoComplete}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                required={props.required}
            />
            {props.children}
        </span>
    );
};

export default TextInput;
