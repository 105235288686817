import React from "react";
import ReactDOM from "react-dom/client";
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
    LanguageProvider,
    ReactQueryProvider,
    ThemeProvider,
    ToastProvider,
} from "@utils/providers";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "@assets/css/fonts.css";
import "@assets/css/index.css";

library.add(faArrowLeft as IconDefinition);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <LanguageProvider>
            <ThemeProvider>
                <ReactQueryProvider>
                    <App />
                    <ToastProvider />
                </ReactQueryProvider>
            </ThemeProvider>
        </LanguageProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
if (process.env.NODE_ENV === "production") {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === "development") reportWebVitals();
