import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CreateGameInput, useCreateGame } from "@utils/api";
import { CreateGameForm } from "@components/forms";
import { Main } from "@components/layout";
import { Heading } from "@components/texts";

const CreateGame: React.FC = () => {
    const form = "create-game-form";
    const translate = useIntl().formatMessage;
    const navigate = useNavigate();

    const createGameMutation = useCreateGame();

    /* istanbul ignore next */
    const onSubmit = (data: CreateGameInput) => {
        if (createGameMutation.isLoading) return;
        createGameMutation.mutate(data, {
            onSuccess: response => {
                navigate(`/game/${response.code}`);
            },
        });
    };

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.create-game" })}</Heading>
            </header>
            <CreateGameForm
                form={form}
                onSubmit={onSubmit}
                submitting={createGameMutation.isLoading}
            />
        </Main>
    );
};

export default CreateGame;
