import React from "react";
import styles from "./css/Subheading.module.css";
import sharedStyles from "../css/Shared.module.css";
import SharedProps from "../props";

const Subheading: React.FC<SharedProps> = props => {
    return (
        <h2
            className={`${styles.subheading} ${sharedStyles.shared}${
                props.className ? ` ${props.className}` : ``
            }`}
            style={props.style}
        >
            {props.children}
        </h2>
    );
};

export { Subheading };
