import axiosInstance from "@utils/axios";
import { Drink } from "@utils/types/drinks";
import { CreateDrinkInput } from "./types";

const createDrink = async (code: string, data: CreateDrinkInput) => {
    const response = await axiosInstance.post(`drinks/${code}`, data);
    return response.data as Drink;
};

export { createDrink };
