import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CreateUserInput, useRegister } from "@utils/api";
import { RegisterForm } from "@components/forms";
import { Main } from "@components/layout";
import { Heading } from "@components/texts";

const Register: React.FC = () => {
    const form = "regiser-form";
    const translate = useIntl().formatMessage;
    const navigate = useNavigate();

    const registerMutation = useRegister();

    /* istanbul ignore next */
    const onSubmit = (data: CreateUserInput) => {
        if (registerMutation.isLoading) return;
        registerMutation.mutate(data, {
            onSuccess: () => {
                navigate("/");
            },
        });
    };

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.register" })}</Heading>
            </header>
            <RegisterForm form={form} onSubmit={onSubmit} submitting={registerMutation.isLoading} />
        </Main>
    );
};

export default Register;
