import React from "react";
import { useIntl } from "react-intl";
import styles from "./css/HomeAuthenticated.module.css";
import Button from "@components/buttons";
import { Main } from "@components/layout";
import Text, { Heading } from "@components/texts";
import { useLogout } from "@utils/api";

const HomeAuthenticated: React.FC = () => {
    const translate = useIntl().formatMessage;
    const logoutMutation = useLogout();

    /* istanbul ignore next */
    const onLogout = () => {
        if (logoutMutation.isLoading) return;
        logoutMutation.mutate(undefined);
    };

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.autobus" })}</Heading>
                <Text>{translate({ id: "home.authenticated.subheader" })}</Text>
            </header>
            <div className={styles.buttons}>
                <Button name="join-game" href="/game/join">
                    {translate({ id: "common.join-game" })}
                </Button>
                <Button name="host-game" href="/game/create">
                    {translate({ id: "common.host-game" })}
                </Button>
                <Button name="logout" onClick={onLogout}>
                    {translate({ id: "common.logout" })}
                </Button>
            </div>
        </Main>
    );
};

export default HomeAuthenticated;
