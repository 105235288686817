import React from "react";
import styles from "./css/LanguagePopup.module.css";
import { RecognizedLanguage } from "@utils/providers";
import LanguageOption from "./option";
import { LanguageSelectOption } from "./types";

interface Props {
    options: LanguageSelectOption[];
    language: RecognizedLanguage;
    setLanguage: (language: RecognizedLanguage) => void;
}

const LanguagePopup: React.FC<Props> = props => {
    return (
        <ul className={styles.popup}>
            {props.options.map(option => (
                <LanguageOption
                    key={option.locale}
                    language={option}
                    setLanguage={props.setLanguage}
                    isSelected={option.locale === props.language}
                />
            ))}
        </ul>
    );
};

export default LanguagePopup;
