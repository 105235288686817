import React, { useCallback, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import { LanguageMap, RecognizedLanguage } from "./LanguageMap";
import { LanguageContext } from "./context";
import { DEFAULT_LANGUAGE, LANGUAGE_STORAGE_KEY } from "./consts";

interface Props {
    children: React.ReactNode;
}

const LanguageProvider: React.FC<Props> = props => {
    const [_locale, _setLocale] = useState<RecognizedLanguage | undefined>(undefined);

    const locale = useMemo(() => {
        if (_locale) return _locale;
        const storedLocale = localStorage.getItem(LANGUAGE_STORAGE_KEY);
        if (storedLocale) return storedLocale as RecognizedLanguage;
        if ("language" in navigator) {
            const navigatorLanguage = navigator.language as RecognizedLanguage;
            if (LanguageMap[navigatorLanguage]) return navigatorLanguage;
        }
        return DEFAULT_LANGUAGE;
    }, [_locale]);

    const messages = useMemo(() => {
        const language = LanguageMap[locale];
        if (!language) {
            console.info(`Locale '${locale}' is not defined, using default: '${DEFAULT_LANGUAGE}'`);
            return LanguageMap[DEFAULT_LANGUAGE].source;
        }
        return language.source;
    }, [locale]);

    const setLocale = useCallback((locale: RecognizedLanguage | null) => {
        if (!locale) {
            localStorage.removeItem(LANGUAGE_STORAGE_KEY);
        } else {
            localStorage.setItem(LANGUAGE_STORAGE_KEY, locale);
        }
        _setLocale(locale || DEFAULT_LANGUAGE);
    }, []);

    return (
        <LanguageContext.Provider value={{ locale: locale, setLocale: setLocale }}>
            <IntlProvider locale={locale} messages={messages}>
                {props.children}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};

export { LanguageProvider };
