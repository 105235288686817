import React from "react";
import { useIntl } from "react-intl";
import styles from "./css/HomeUnauthenticated.module.css";
import Button from "@components/buttons";
import { Main } from "@components/layout";
import Text, { Heading } from "@components/texts";

const HomeUnauthenticated: React.FC = () => {
    const translate = useIntl().formatMessage;

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.autobus" })}</Heading>
                <Text>{translate({ id: "home.unauthenticated.subheader" })}</Text>
            </header>
            <div className={styles.buttons}>
                <Button name="login-button" href="/login">
                    {translate({ id: "common.login" })}
                </Button>
                <Button name="register-button" href="/register">
                    {translate({ id: "common.register" })}
                </Button>
            </div>
        </Main>
    );
};

export default HomeUnauthenticated;
