import { CookieName } from "./types";

/**
 * Return a cookie based on the given name.
 * @param name the name of the cookie.
 * @returns the desired cookie, or null if it does not exist.
 */
/* istanbul ignore next */
const getCookie = (name: CookieName) => {
    const nameEQ = name + "=";
    const cookie = document.cookie.split(";");

    for (let i = 0; i < cookie.length; i++) {
        let c = cookie[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export { getCookie };
