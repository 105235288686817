import axiosInstance, { authInstance } from "@utils/axios";
import { TokenResponseType, User } from "@utils/types";
import { CreateUserInput } from "./types";

const getSelf = async () => {
    const response = await axiosInstance.get("users/");
    return response.data as User;
};

const createUser = async (data: CreateUserInput) => {
    const response = await authInstance.post("users/", data);
    return response.data as TokenResponseType;
};

export { createUser, getSelf };
