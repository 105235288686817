/* istanbul ignore file */
import { createContext } from "react";
import { Theme } from "./types";

interface Context {
    theme: Theme;
    changeTheme: (targetTheme?: Theme, persist?: boolean) => void;
}

const ThemeContext = createContext<Context>({
    theme: "light",
    changeTheme: (targetTheme?: Theme, persist = true) => {},
});

export { ThemeContext };
