import React from "react";
import { Deck as DeckType } from "@utils/types";
import { Card } from "@components/game";

interface Props {
    deck: DeckType;
}

const Deck: React.FC<Props> = props => {
    if (!props.deck.top_card) return <React.Fragment />;

    return (
        <Card
            suit={props.deck.top_card.suit}
            value={props.deck.top_card.value}
            visible={props.deck.top_card.visible}
            back={props.deck.top_card.back}
        />
    );
};

export { Deck };
