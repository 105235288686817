import React from "react";
import { useIntl } from "react-intl";
import styles from "./css/Players.module.css";
import { PlayerConnection } from "@components/game";
import Text from "@components/texts";
import { Spinner } from "@components/spinner";
import { GameConnection } from "@utils/types";

interface Props {
    gameConnections: GameConnection[];
    loading: boolean;
}

const Players: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;

    if (props.loading && props.gameConnections.length < 1) {
        return <Spinner centered />;
    }

    if (props.gameConnections.length < 1) {
        return <Text>{translate({ id: "game.players.error.failed-to-fetch" })}</Text>;
    }

    return (
        <ul className={styles.playerList}>
            {props.gameConnections.map(gameConnection => (
                <PlayerConnection
                    key={gameConnection.user.username}
                    playerConnection={gameConnection}
                />
            ))}
        </ul>
    );
};

export default Players;
