import React from "react";
import TextInput from "../text";
import SharedProps from "../props";

const NumericInput: React.FC<SharedProps> = props => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.maxLength && event.target.value.length > props.maxLength) return;
        props.setValue(event.target.value);
    };

    return (
        <TextInput {...props} type="number" inputMode="numeric" onChange={onChange}>
            {props.children}
        </TextInput>
    );
};

export { NumericInput };
