import { useEffect } from "react";

const useOnBeforeUnload = (callback: () => void) => {
    useEffect(() => {
        window.addEventListener("beforeunload", callback);
        return () => {
            window.removeEventListener("beforeunload", callback);
        };
    }, [callback]);
};

export { useOnBeforeUnload };
