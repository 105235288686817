import React, { useState } from "react";
import sharedStyles from "../css/Shared.module.css";
import Button from "@components/buttons";
import { useIntl } from "react-intl";

const VersionInfo: React.FC = () => {
    const translate = useIntl().formatMessage;
    const [version] = useState(process.env.VERSION || "development");

    return (
        <Button
            className={sharedStyles.shared}
            name="languageSelector"
            onClick={() => console.warn("Version button is not implemented")}
        >
            {translate({ id: "common.version" })}: {version}
        </Button>
    );
};

export default VersionInfo;
