import React from "react";
import styles from "./css/Card.module.css";
import Text from "@components/texts";
import { CardBack, CardSuit, CardValue } from "@utils/types";
import { MapCardBack } from "./consts";

interface Props {
    value: CardValue | null;
    suit: CardSuit | null;
    visible: boolean;
    back: CardBack;
    small?: boolean;
}

const Card: React.FC<Props> = props => {
    if (!props.visible || !props.suit || !props.value)
        return (
            <span
                className={`${styles.card} ${
                    props.small ? styles.card_small : styles.card_regular
                } ${MapCardBack[props.back]}`}
                data-testid="card"
            />
        );

    return (
        <span
            className={`${styles.card} ${props.small ? styles.card_small : styles.card_regular}`}
            data-testid="card"
        >
            <Text>
                {props.value} of {props.suit}
            </Text>
        </span>
    );
};

export { Card };
