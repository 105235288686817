import React from "react";
import styles from "./css/LanguageOption.module.css";
import { RecognizedLanguage } from "@utils/providers";
import Button from "@components/buttons/button";
import sharedStyles from "../../../css/Shared.module.css";
import { LanguageSelectOption } from "../types";

interface Props {
    isSelected: boolean;
    language: LanguageSelectOption;
    setLanguage: (language: RecognizedLanguage) => void;
}

const LanguageOption: React.FC<Props> = props => {
    const handleClick = () => {
        if (props.isSelected) return;
        props.setLanguage(props.language.locale);
    };

    return (
        <li>
            <Button
                name={"language"}
                className={`${sharedStyles.shared} ${styles.button}${
                    props.isSelected ? ` ${styles.selected}` : ``
                }`}
                onClick={() => handleClick()}
            >
                {props.language.displayName}
            </Button>
        </li>
    );
};

export default LanguageOption;
