import React, { useState } from "react";
import { useIntl } from "react-intl";
import Form, { Fieldset } from "@components/forms";
import TextInput from "@components/inputs";
import { SubmitButton } from "@components/buttons";
import { CreateGameInput } from "@utils/api";
import { NumericInput } from "@components/inputs";
import { SharedProps } from "../../props";

interface Props extends SharedProps<CreateGameInput> {}

const CreateGameForm: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;
    const [name, setName] = useState("");
    const [code, setCode] = useState("");

    const onSubmit = () => {
        const data = {
            name: name,
            code: code,
        };
        props.onSubmit(data);
    };

    return (
        <Form form={props.form} className={props.className} onSubmit={onSubmit}>
            <Fieldset form={props.form}>
                <TextInput
                    value={name}
                    setValue={setName}
                    form={props.form}
                    name="name"
                    placeholder={translate({ id: "form.game.create.name" })}
                    autoComplete="name"
                    maxLength={16}
                    autoFocus
                    required
                />
                <NumericInput
                    value={code}
                    setValue={setCode}
                    form={props.form}
                    name="code"
                    placeholder={translate({ id: "form.game.create.code" })}
                    autoComplete="code"
                    maxLength={4}
                />
            </Fieldset>
            <SubmitButton form={props.form} name="submitCreateGame" submitting={props.submitting}>
                {translate({ id: "common.create-game" })}
            </SubmitButton>
        </Form>
    );
};

export { CreateGameForm };
