import React from "react";
import styles from "./css/Text.module.css";
import sharedStyles from "../css/Shared.module.css";
import SharedProps from "../props";

const Text: React.FC<SharedProps> = props => {
    return (
        <p
            className={`${styles.text} ${sharedStyles.shared}${
                props.className ? ` ${props.className}` : ``
            }`}
            style={props.style}
        >
            {props.children}
        </p>
    );
};

export default Text;
