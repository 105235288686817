import React, { useContext, useMemo } from "react";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./css/ThemeToggle.module.css";
import sharedStyles from "../css/Shared.module.css";
import { ThemeContext } from "@utils/providers";
import Button from "@components/buttons/button";

const ThemeToggle: React.FC = () => {
    const { theme, changeTheme } = useContext(ThemeContext);
    const icon = useMemo(() => {
        if (theme === "light") return <FontAwesomeIcon icon={faMoon} className={styles.theme} />;
        return <FontAwesomeIcon icon={faSun} className={styles.theme} />;
    }, [theme]);

    return (
        <Button className={sharedStyles.shared} name="toggleTheme" onClick={() => changeTheme()}>
            {icon}
        </Button>
    );
};

export default ThemeToggle;
