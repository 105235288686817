import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import styles from "./css/GameInputForm.module.css";
import { AceValue as AceValueType, Game, GameConnection } from "@utils/types";
import { SubmitButton } from "@components/buttons";
import Form from "@components/forms";
import { Spinner } from "@components/spinner";
import Text from "@components/texts";
import { Card } from "@components/game";
import { useCreateDrink, useCreateGameMove } from "@utils/api";
import { GuessInputType } from "./guess/consts";
import { AceValueRow } from "./ace";
import { Drinks } from "@components/game/drinks";
import GameInput from "./input/GameInput";
import { WarningIcon } from "@components/icons";

interface Props {
    game: Game;
    gameConnection?: GameConnection;
    loading: boolean;
}

const GameInputForm: React.FC<Props> = props => {
    const form = "game-input";
    const translate = useIntl().formatMessage;
    const [guess, setGuess] = useState<GuessInputType>();
    const [aceValue, setAceValue] = useState<AceValueType>();
    const [aceValueRequired, setAceValueRequired] = useState(false);
    const [drinkingUser, setDrinkingUser] = useState<GameConnection | undefined>();
    const [drinksRequired, setDrinksRequired] = useState(false);
    const createMoveMutation = useCreateGameMove(
        props.game.code,
        setAceValueRequired,
        setDrinksRequired
    );
    const createDrinkMutation = useCreateDrink(props.game.code);

    const isUsersRound = useMemo(() => {
        if (!props.gameConnection || !props.game.current_connection_turn) return false;
        return (
            props.gameConnection.user.username === props.game.current_connection_turn.user.username
        );
    }, [props.game, props.gameConnection]);

    /* istanbul ignore next */
    const onSubmit = useCallback(() => {
        if (!isUsersRound) return;
        if (drinksRequired) {
            if (createDrinkMutation.isLoading) return;
            if (!drinkingUser)
                return toast.warning(translate({ id: "game.input.error.select-user-for-drink" }), {
                    icon: WarningIcon,
                });
            createDrinkMutation.mutate(
                { target_username: drinkingUser.user.username },
                {
                    onSuccess: () => {
                        setGuess(undefined);
                        setDrinkingUser(undefined);
                        setDrinksRequired(false);
                    },
                }
            );
            return;
        }
        if (createMoveMutation.isLoading) return;
        if (!guess)
            return toast.warning(translate({ id: "game.input.warning.pick-guess" }), {
                icon: WarningIcon,
            });
        if (aceValueRequired && !aceValue)
            return toast.warning(translate({ id: "game.input.warning.select-ace-value" }), {
                icon: WarningIcon,
            });
        createMoveMutation.mutate(
            { guess: guess, ace_value: aceValue },
            {
                onSuccess: () => {
                    setGuess(undefined);
                    setDrinkingUser(undefined);
                },
            }
        );
    }, [
        isUsersRound,
        drinksRequired,
        createMoveMutation,
        guess,
        aceValueRequired,
        aceValue,
        createDrinkMutation,
        drinkingUser,
        translate,
    ]);

    /* istanbul ignore next */
    const handleAceValue = useCallback(
        (value: AceValueType | undefined) => {
            if (props.gameConnection?.ace_value) return;
            setAceValue(value);
        },
        [props.gameConnection?.ace_value]
    );

    if (props.loading && !props.gameConnection) {
        return <Spinner centered />;
    }

    if (!props.gameConnection) {
        return <Text>{translate({ id: "game.input.error.failed-to-fetch-user" })}</Text>;
    }

    if (!props.game.stage) {
        return <Text>{translate({ id: "game.input.error.game-stage-unset" })}</Text>;
    }

    return (
        <Form form={form} onSubmit={onSubmit} className={styles.form}>
            <header className={styles.header}>
                <AceValueRow
                    aceValue={props.gameConnection.ace_value}
                    selectedValue={aceValue}
                    setSelectedValue={handleAceValue}
                />
                <Drinks gameConnection={props.gameConnection} />
            </header>
            <GameInput
                form={form}
                game={props.game}
                gameConnection={props.gameConnection}
                isUsersRound={isUsersRound}
                drinksRequired={drinksRequired}
                selectedDrinkingUser={drinkingUser}
                setSelectedDrinkingUser={setDrinkingUser}
                aceValueRequired={aceValueRequired}
                aceValue={aceValue}
                handleAceValue={handleAceValue}
                guess={guess}
                setGuess={setGuess}
                onSubmit={onSubmit}
            />
            <ul className={styles.cards}>
                {props.gameConnection.cards.map(card => (
                    <Card
                        key={
                            card.value && card.suit
                                ? `card-${card.value}-${card.suit}-${card.back}`
                                : `card-${card.index}-${card.back}`
                        }
                        suit={card.suit}
                        value={card.value}
                        visible={card.visible}
                        back={card.back}
                    />
                ))}
            </ul>
            {(!!guess || (aceValueRequired && aceValue)) && isUsersRound && (
                <footer className={styles.footer} data-testid="submit-guess">
                    <SubmitButton form={form} name="submit" className={styles.submit} autoFocus>
                        {translate({ id: "common.submit" })}
                    </SubmitButton>
                </footer>
            )}
        </Form>
    );
};

export default GameInputForm;
