import React, { HTMLAttributeAnchorTarget } from "react";
import { NavLink } from "react-router-dom";
import styles from "./css/Link.module.css";
import sharedStyles from "../css/Shared.module.css";
import SharedProps from "../props";

interface Props {
    to: string;
    target?: HTMLAttributeAnchorTarget;
}

const Link: React.FC<Omit<SharedProps, "onClick"> & Props> = props => {
    return (
        <NavLink
            className={`${styles.link} ${sharedStyles.shared}${
                props.className ? ` ${props.className}` : ``
            }`}
            style={props.style}
            to={props.to}
            target={props.target}
            tabIndex={0}
        >
            {props.children}
        </NavLink>
    );
};

export { Link };
