import React from "react";
import styles from "./css/Button.module.css";
import { Link } from "@components/texts";
import SharedProps from "../props";
import { Spinner } from "@components/spinner";

type ButtonType = "submit" | "reset" | "button" | undefined;

interface Props extends SharedProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    name: string;
    type?: ButtonType;
    focused?: boolean;
    href?: string;
}

const Button: React.FC<Props> = props => {
    if (props.href) {
        return (
            <Link
                to={props.href}
                className={`${styles.link}${props.focused ? ` ${styles.focused}` : ``}${
                    props.className ? ` ${props.className}` : ``
                }`}
            >
                {props.children}
            </Link>
        );
    }

    return (
        <button
            type={props.type ? props.type : "button"}
            form={props.form}
            onClick={props.onClick}
            className={`${styles.button}${props.focused ? ` ${styles.focused}` : ``}${
                props.className ? ` ${props.className}` : ``
            }`}
            tabIndex={props.tabIndex || 0}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            aria-label={props.name}
            name={props.name}
        >
            {props.children}
            {props.submitting && <Spinner absolute />}
        </button>
    );
};

export default Button;
