import React from "react";
import { useIntl } from "react-intl";
import { GuessInput } from "../input";
import { GuessInclusive } from "../consts";
import SharedProps from "./props";

const ThirdGuess: React.FC<SharedProps> = props => {
    const translate = useIntl().formatMessage;

    return (
        <React.Fragment>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessInclusive.INCLUSIVE}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.third-guess.inclusive" })}
            </GuessInput>
            <GuessInput
                handleClick={props.setGuess}
                value={GuessInclusive.EXCLUSIVE}
                currentGuess={props.currentGuess}
                isDisabled={props.isDisabled}
                form={props.form}
            >
                {translate({ id: "game.input.guesses.third-guess.exclusive" })}
            </GuessInput>
        </React.Fragment>
    );
};

export { ThirdGuess };
