import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";

interface Props {
    children: React.ReactNode;
}

const ReactQueryProvider: React.FC<Props> = props => {
    const MINUTE = 60000;
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 15 * MINUTE,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: true,
                retry: false,
            },
        },
    });

    useEffect(() => {
        setLogger({
            log: () => {},
            warn: () => {},
            error: () => {},
        });
    }, []);

    return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};

export { ReactQueryProvider };
