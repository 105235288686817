import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import styles from "./css/PlayerConnection.module.css";
import { GameConnection } from "@utils/types";
import Text from "@components/texts";
import { Card } from "@components/game";
import { Drinks } from "../drinks";

interface Props {
    playerConnection: GameConnection;
}

const PlayerConnection: React.FC<Props> = props => {
    const connectionIcon = useMemo(() => {
        if (props.playerConnection.ready)
            return (
                <FontAwesomeIcon
                    data-testid="connectionStatus"
                    color="var(--accent-green)"
                    fontSize="var(--font-size-regular)"
                    icon={faCheckCircle}
                />
            );
        return (
            <FontAwesomeIcon
                data-testid="connectionStatus"
                color="var(--accent-red)"
                fontSize="var(--font-size-regular)"
                icon={faCircleXmark}
            />
        );
    }, [props.playerConnection.ready]);

    return (
        <li className={styles.player} data-testid="player">
            <header className={styles.header}>
                <span className={styles.header_left}>
                    <Text>{props.playerConnection.user.username}</Text>
                    {connectionIcon}
                </span>
                <Drinks gameConnection={props.playerConnection} />
            </header>
            <ul className={styles.cards}>
                {props.playerConnection.cards.map(card => (
                    <Card
                        key={
                            card.value && card.suit
                                ? `card-${card.value}-${card.suit}-${card.back}`
                                : `card-${card.index}-${card.back}`
                        }
                        suit={card.suit}
                        value={card.value}
                        visible={card.visible}
                        back={card.back}
                        small
                    />
                ))}
            </ul>
        </li>
    );
};

export { PlayerConnection };
