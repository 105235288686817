import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ErrorResponseData } from "./types";
import { ErrorIcon } from "@components/icons";

/**
 * Handle an error that has occurred during an API call.
 * @param error The axios error object.
 * @param message A message to display in the toast.
 * @param messageOptions Custom error messages for specific status codes. The key is the status code and the value is the message.
 * @returns the error object.
 */
const handleAPIError = (
    error: AxiosError<ErrorResponseData>,
    message: string,
    messageOptions?: { [key: number]: string }
) => {
    if (error.code === "ERR_NETWORK") {
        toast.error("Unable to connect to the server", { icon: ErrorIcon });
        return error;
    }
    if (messageOptions && error.response && messageOptions[error.response.status])
        message = messageOptions[error.response.status];
    console.error(
        `${message}${error.response ? `: ${error.response.data.detail}` : ""} [${error.message}]`
    );
    toast.error(message, { icon: ErrorIcon });
    return error;
};

const handleWebSocketError = () => {
    console.error(`Failed to connect to WebSockets`);
    toast.error("Unable to receive live updates", { icon: ErrorIcon });
};

export { handleAPIError, handleWebSocketError };
