import { useCallback, useEffect, useMemo } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Game, GameMove, GameSocketUpdate, GameStatus, MoveOutcome } from "@utils/types";
import {
    getGame,
    patchGameConnection,
    joinGame,
    createGame,
    gameWebSockets,
    launchGame,
    createGameMove,
} from "./consumers";
import { CreateGameInput, CreateGameMoveInput, JoinGameInput } from "./types";
import { handleAPIError, handleWebSocketError } from "../utils";
import { ErrorResponseData } from "../types";
import { InfoIcon, SuccessIcon, WarningIcon } from "@components/icons";

const useGame = (code: string) => {
    const translate = useIntl().formatMessage;
    return useQuery<Game, AxiosError<ErrorResponseData>>({
        queryKey: ["games", code],
        queryFn: () => getGame(code),
        staleTime: 0,
        onError: async error => handleAPIError(error, translate({ id: "api.games.load.error" })),
    });
};

const useCreateGame = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<Game, AxiosError<ErrorResponseData>, CreateGameInput>({
        mutationFn: data => createGame(data),
        onSuccess: async response => {
            queryClient.setQueryData(["games", response.code], response);
            toast.success(translate({ id: "api.games.create.success" }), { icon: SuccessIcon });
            return response;
        },
        onError: async error => handleAPIError(error, translate({ id: "api.games.create.error" })),
    });
};

const useJoinGame = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<Game, AxiosError<ErrorResponseData>, JoinGameInput>({
        mutationFn: data => joinGame(data),
        onSuccess: async response => {
            queryClient.setQueryData(["games", response.code], response);
            return response;
        },
        onError: async error => handleAPIError(error, translate({ id: "api.games.join.error" })),
    });
};

const useGetReadyOnGame = (code: string) => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<Game, AxiosError<ErrorResponseData>>({
        mutationFn: () => patchGameConnection(code, { ready: true }),
        onSuccess: async response => {
            queryClient.setQueryData(["games", code], response);
            return response;
        },
        onError: async error =>
            handleAPIError(error, translate({ id: "api.games.get-ready.error" })),
    });
};

const useLeaveGame = (code: string) => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<Game, AxiosError<ErrorResponseData>>({
        mutationFn: () => patchGameConnection(code, { ready: false }),
        onSuccess: async response => {
            queryClient.setQueryData(["games", code], response);
            return response;
        },
        onError: async error => handleAPIError(error, translate({ id: "api.games.leave.error" })),
    });
};

const useLaunchGame = (code: string) => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<Game, AxiosError<ErrorResponseData>>({
        mutationFn: () => launchGame(code, { status: GameStatus.RUNNING }),
        onSuccess: async response => {
            queryClient.setQueriesData(["games", code], response);
            toast.success(translate({ id: "api.games.launch.success" }), { icon: SuccessIcon });
            return response;
        },
        onError: async error => handleAPIError(error, translate({ id: "api.games.launch.error" })),
    });
};

const useCreateGameMove = (
    code: string,
    setAceValueRequired?: React.Dispatch<React.SetStateAction<boolean>>,
    setDrinksRequired?: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const translate = useIntl().formatMessage;
    const ACE_VALUE_STATUS_CODE = 300;
    const DRINK_REQUIRED_STATUS_CODE = 303;

    return useMutation<GameMove, AxiosError<ErrorResponseData>, CreateGameMoveInput>({
        mutationFn: data => createGameMove(code, data),
        onSuccess: async response => {
            if (response.outcome === MoveOutcome.CORRECT) {
                toast.success(translate({ id: "api.games.move.evaluate.correct" }), {
                    icon: SuccessIcon,
                });
            } else if (response.outcome === MoveOutcome.INCORRECT) {
                toast.warning(translate({ id: "api.games.move.evaluate.incorrect" }), {
                    icon: WarningIcon,
                });
            } else {
                toast.warning(translate({ id: "api.games.move.evaluate.neutral" }), {
                    icon: InfoIcon,
                });
            }
            if (setAceValueRequired) setAceValueRequired(false);
            if (setDrinksRequired) setDrinksRequired(false);
            return response;
        },
        onError: async error => {
            if (
                error.response &&
                error.response.status === ACE_VALUE_STATUS_CODE &&
                setAceValueRequired
            ) {
                setAceValueRequired(true);
                if (setDrinksRequired) setDrinksRequired(false);
                return;
            }
            if (
                error.response &&
                error.response.status === DRINK_REQUIRED_STATUS_CODE &&
                setDrinksRequired
            ) {
                setDrinksRequired(true);
                if (setAceValueRequired) setAceValueRequired(false);
                return;
            }
            handleAPIError(error, translate({ id: "api.games.move.error" }));
        },
    });
};

const useGameWebSockets = (
    code: string | undefined,
    callback: (update: GameSocketUpdate) => void
) => {
    const socket = useMemo(() => {
        if (!code) return;
        return gameWebSockets(code);
    }, [code]);

    const handleCallback = useCallback(
        (message: MessageEvent<string>) => callback(JSON.parse(message.data) as GameSocketUpdate),
        [callback]
    );

    useEffect(() => {
        if (!socket) return;
        socket.addEventListener("message", handleCallback);
        return () => socket.removeEventListener("message", handleCallback);
    }, [socket, handleCallback]);

    useEffect(() => {
        if (!socket || socket.readyState === WebSocket.OPEN) return;
        socket.onopen = () => {
            console.info("WebSocket connection opened.");
        };
        socket.onclose = () => console.info("WebSocket connection closed.");
        socket.onerror = () => handleWebSocketError();
        return () => {
            if (socket.readyState === WebSocket.OPEN) socket.close();
        };
    }, [socket]);
};

export {
    useGame,
    useGetReadyOnGame,
    useLeaveGame,
    useLaunchGame,
    useJoinGame,
    useCreateGame,
    useCreateGameMove,
    useGameWebSockets,
};
