import React, { useState } from "react";
import { useIntl } from "react-intl";
import styles from "./css/PasswordInput.module.css";
import Text from "@components/texts";
import TextInput from "../text";
import SharedProps from "../props";

const PasswordInput: React.FC<SharedProps> = props => {
    const translate = useIntl().formatMessage;
    const [visible, setVisible] = useState(false);

    return (
        <TextInput {...props} type={visible ? "text" : "password"} minLength={8}>
            {props.children}
            <span className={styles.span} onClick={() => setVisible(prev => !prev)}>
                <Text>
                    {visible
                        ? translate({ id: "components.inputs.password.hide" })
                        : translate({ id: "components.inputs.password.show" })}
                </Text>
            </span>
        </TextInput>
    );
};

export { PasswordInput };
