import React, { useState } from "react";
import { useIntl } from "react-intl";
import { LoginInput } from "@utils/api";
import { SubmitButton } from "@components/buttons";
import Form, { Fieldset } from "@components/forms";
import TextInput, { Checkbox, PasswordInput } from "@components/inputs";
import Text, { Link } from "@components/texts";
import { SharedProps } from "../../props";

interface Props extends SharedProps<LoginInput> {}

const LoginForm: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);

    const onSubmit = () => {
        const data = {
            username: username,
            password: password,
            remember: remember,
        };
        props.onSubmit(data);
    };

    return (
        <Form form={props.form} className={props.className} onSubmit={onSubmit}>
            <Fieldset form={props.form}>
                <TextInput
                    value={username}
                    setValue={setUsername}
                    form={props.form}
                    name="username"
                    placeholder={translate({ id: "auth.username" })}
                    autoComplete="username"
                    maxLength={16}
                    autoFocus
                    required
                />
                <PasswordInput
                    value={password}
                    setValue={setPassword}
                    form={props.form}
                    name="password"
                    placeholder={translate({ id: "auth.password" })}
                    autoComplete="current-password"
                    required
                />
                <Checkbox value={remember} setValue={setRemember} form={props.form} name={""}>
                    {translate({ id: "auth.remember-login" })}
                </Checkbox>
            </Fieldset>
            <SubmitButton form={props.form} name="submitLogin" submitting={props.submitting}>
                {translate({ id: "common.login" })}
            </SubmitButton>
            <Text>
                {translate({ id: "auth.login.prompt.already-have-account" })}?{" "}
                <Link to="/register">{translate({ id: "common.register" })}</Link>
            </Text>
        </Form>
    );
};

export { LoginForm };
