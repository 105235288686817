import React, { useState } from "react";
import { useIntl } from "react-intl";
import { JoinGameInput } from "@utils/api";
import Form, { Fieldset } from "@components/forms";
import { SubmitButton } from "@components/buttons";
import { NumericInput } from "@components/inputs";
import { SharedProps } from "../../props";

interface Props extends SharedProps<JoinGameInput> {}

const JoinGameForm: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;
    const [code, setCode] = useState("");

    const onSubmit = () => {
        const data = {
            code: code,
        };
        props.onSubmit(data);
    };

    return (
        <Form form={props.form} className={props.className} onSubmit={onSubmit}>
            <Fieldset form={props.form}>
                <NumericInput
                    value={code}
                    setValue={setCode}
                    form={props.form}
                    name="code"
                    placeholder={translate({ id: "form.game.join.code" })}
                    maxLength={4}
                    minLength={4}
                    autoFocus
                    required
                />
            </Fieldset>
            <SubmitButton form={props.form} name="submitJoinGame" submitting={props.submitting}>
                {translate({ id: "common.join-game" })}
            </SubmitButton>
        </Form>
    );
};

export { JoinGameForm };
