import React, { FormEvent, ReactNode } from "react";
import styles from "./css/Form.module.css";

interface Props {
    form: string;
    onSubmit: () => void;
    children: ReactNode;
    className?: string;
}

const Form: React.FC<Props> = props => {
    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        props.onSubmit();
    };

    return (
        <form
            id={props.form}
            onSubmit={onSubmit}
            className={`${styles.form}${props.className ? ` ${props.className}` : ``}`}
        >
            {props.children}
        </form>
    );
};

export default Form;
