import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { BlocklistedTokenResponseType, TokenResponseType } from "@utils/types";
import { SuccessIcon } from "@components/icons";
import { login, logout } from "./consumers";
import { LoginInput } from "./types";
import { ErrorResponseData } from "../types";
import { handleAPIError } from "../utils";
import { useIntl } from "react-intl";

const useLogin = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();

    return useMutation<TokenResponseType, AxiosError<ErrorResponseData>, LoginInput>({
        mutationFn: data => login(data),
        onSuccess: async response => {
            queryClient.invalidateQueries("self");
            queryClient.refetchQueries("self");
            toast.success(translate({ id: "api.auth.login.success" }), { icon: SuccessIcon });
            return response;
        },
        onError: async error => {
            const errorMessages = {
                404: translate({ id: "api.auth.login.error.invalid-username" }),
                401: translate({ id: "api.auth.login.error.invalid-password" }),
            };
            return handleAPIError(
                error,
                translate({ id: "api.auth.login.error.generic" }),
                errorMessages
            );
        },
    });
};

const useLogout = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation<BlocklistedTokenResponseType, AxiosError<ErrorResponseData>, undefined>({
        mutationFn: () => logout(),
        onError: async error => {
            const errorMessages = {
                400: translate({ id: "api.auth.logout.error.invalid-token" }),
                401: translate({ id: "api.auth.logout.error.token-blocklisted" }),
            };
            return handleAPIError(
                error,
                translate({ id: "api.auth.logout.error.generic" }),
                errorMessages
            );
        },
        onSettled: async response => {
            queryClient.invalidateQueries("self");
            queryClient.removeQueries("self");
            toast.success(translate({ id: "api.auth.logout.success" }), { icon: SuccessIcon });
            navigate("/");
            return response;
        },
    });
};

export { useLogin, useLogout };
