import React, { useCallback, useEffect, useState } from "react";
import styles from "@assets/css/themes.module.css";
import { ThemeContext } from "./context";
import { Theme } from "./types";

interface Props {
    children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = props => {
    const THEME_STORAGE_KEY = "theme";
    const [theme, setTheme] = useState<Theme>("light");

    const getDefaultTheme = useCallback(() => {
        const theme = localStorage.getItem(THEME_STORAGE_KEY) as Theme;
        if (theme === "light" || theme === "dark") return theme;
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) return "dark";
        return "light";
    }, []);

    const changeTheme = useCallback(
        (targetTheme?: Theme, persist = true) => {
            const rootClasses = document.body.classList;
            if (persist) rootClasses.add(styles.body_transition);
            let theme;
            if (targetTheme === undefined) {
                if (rootClasses.contains(styles.light)) {
                    theme = styles.dark;
                    targetTheme = "dark";
                } else {
                    theme = styles.light;
                    targetTheme = "light";
                }
            } else {
                theme = styles[targetTheme];
            }
            rootClasses.remove(targetTheme === "light" ? styles.dark : styles.light);
            rootClasses.add(theme);
            if (persist) localStorage.setItem(THEME_STORAGE_KEY, targetTheme);
            setTheme(targetTheme);
            setTimeout(() => {
                rootClasses.remove(styles.body_transition);
            }, 300);
        },
        [setTheme]
    );

    useEffect(() => {
        changeTheme(getDefaultTheme(), false);
    }, [changeTheme, getDefaultTheme]);

    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            {props.children}
        </ThemeContext.Provider>
    );
};

export { ThemeProvider };
