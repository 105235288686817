import React from "react";
import Button from "@components/buttons";
import styles from "./css/AceValueInput.module.css";
import { AceValue } from "@utils/types";
import { useIntl } from "react-intl";

interface Props {
    selected: AceValue | undefined;
    setSelected: (value: AceValue | undefined) => void;
    small?: boolean;
}

const AceValueInput: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;

    const handleOnClick = (value: AceValue) => {
        props.setSelected(value);
    };

    return (
        <span className={styles.row}>
            <Button
                name={"lowest"}
                className={`${props.small ? styles.small : undefined}${
                    props.selected === AceValue.LOWEST ? ` ${styles.selected}` : ``
                }`}
                onClick={() => handleOnClick(AceValue.LOWEST)}
            >
                {translate({ id: "game.input.ace.value.lowest" }).toLocaleLowerCase()}
            </Button>
            <Button
                name={"highest"}
                className={`${props.small ? styles.small : undefined}${
                    props.selected === AceValue.HIGHEST ? ` ${styles.selected}` : ``
                }`}
                onClick={() => handleOnClick(AceValue.HIGHEST)}
            >
                {translate({ id: "game.input.ace.value.highest" }).toLocaleLowerCase()}
            </Button>
        </span>
    );
};

export { AceValueInput };
