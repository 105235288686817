import React from "react";
import { useIntl } from "react-intl";
import styles from "./css/GameTable.module.css";
import { Spinner } from "@components/spinner";
import Text from "@components/texts";
import { Table } from "@utils/types";
import { Deck } from "@components/game";

interface Props {
    table?: Table;
    loading: boolean;
}

const GameTable: React.FC<Props> = props => {
    const translate = useIntl().formatMessage;

    if (props.loading && !props.table) {
        return <Spinner centered />;
    }

    if (!props.table) {
        return <Text>{translate({ id: "game.table.error.failed-to-fetch" })}</Text>;
    }

    return (
        <div className={styles.table}>
            <Deck deck={props.table.deck} />
        </div>
    );
};

export default GameTable;
