enum CardSuit {
    SPADES = "SPADES",
    HEARTS = "HEARTS",
    CLUBS = "CLUBS",
    DIAMONDS = "DIAMONDS",
}

enum CardValue {
    ACE = "ACE",
    TWO = "TWO",
    THREE = "THREE",
    FOUR = "FOUR",
    FIVE = "FIVE",
    SIX = "SIX",
    SEVEN = "SEVEN",
    EIGHT = "EIGHT",
    NINE = "NINE",
    JACK = "JACK",
    QUEEN = "QUEEN",
    KING = "KING",
}

enum CardBack {
    BLUE = "BLUE",
    RED = "RED",
}

interface Card {
    suit: CardSuit | null;
    value: CardValue | null;
    back: CardBack;
    index: number;
    visible: boolean;
}

export { Card, CardSuit, CardValue, CardBack };
