import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { Drink } from "@utils/types/drinks";
import { handleAPIError } from "../utils";
import { ErrorResponseData } from "../types";
import { CreateDrinkInput } from "./types";
import { createDrink } from "./consumers";
import { useIntl } from "react-intl";

const useCreateDrink = (code: string) => {
    const translate = useIntl().formatMessage;

    return useMutation<Drink, AxiosError<ErrorResponseData>, CreateDrinkInput>({
        mutationFn: data => createDrink(code, data),
        onSuccess: async response => {
            return response;
        },
        onError: async error => {
            handleAPIError(error, translate({ id: "api.drinks.give.error" }));
        },
    });
};

export { useCreateDrink };
