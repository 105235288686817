import React from "react";
import styles from "./css/Checkbox.module.css";
import SharedProps from "../props";

interface Props {
    setValue: (value: boolean) => void;
    value: boolean;
    labelClassName?: string;
    switch?: boolean;
}

interface CheckboxType
    extends Omit<
        Omit<SharedProps, "value" | "setValue"> & Props,
        "placeholder" | "minLength" | "maxLength" | "noBaseStyling" | "tabIndex"
    > {
    tabIndex?: number;
}

interface CheckboxInputType extends Omit<CheckboxType, "children"> {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput: React.FC<CheckboxInputType> = props => {
    return (
        <input
            type="checkbox"
            checked={props.value}
            value={props.value.toString()}
            onChange={props.onChange}
            form={props.form}
            name={props.name}
            tabIndex={props.tabIndex || 0}
            className={`${props.switch ? styles.switch : styles.checkbox}${
                props.className ? ` ${props.className}` : ``
            }`}
            required={props.required}
            autoFocus={props.autoFocus}
        />
    );
};

const Checkbox: React.FC<CheckboxType> = props => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setValue(event.target.checked);
    };

    if (!props.children) return <CheckboxInput onChange={handleChange} {...props} />;

    return (
        <label
            className={`${styles.label}${props.labelClassName ? ` ${props.labelClassName}` : ``}`}
            tabIndex={props.tabIndex || 0}
        >
            <CheckboxInput onChange={handleChange} {...props} tabIndex={undefined} />
            {props.children}
        </label>
    );
};

export { Checkbox };
