import React, { useCallback, useMemo } from "react";
import styles from "./css/GameInput.module.css";
import AceValue from "../ace";
import { AceValue as AceValueType, Game, GameStage, GameConnection } from "@utils/types";
import { GuessingInput } from "../guess";
import { GuessInputType } from "../guess/consts";
import DrinksInput from "../drinks/DrinksInput";

interface Props {
    form: string;
    game: Game;
    gameConnection: GameConnection;
    isUsersRound: boolean;
    aceValueRequired: boolean;
    drinksRequired: boolean;
    aceValue: AceValueType | undefined;
    handleAceValue: (value: AceValueType | undefined) => void;
    selectedDrinkingUser: GameConnection | undefined;
    setSelectedDrinkingUser: React.Dispatch<React.SetStateAction<GameConnection | undefined>>;
    guess: GuessInputType | undefined;
    setGuess: React.Dispatch<React.SetStateAction<GuessInputType | undefined>>;
    onSubmit: () => void;
}

const GameInput: React.FC<Props> = props => {
    const isGuessSelected = useMemo(
        () =>
            props.game.stage &&
            [GameStage.GUESSING_GAME, GameStage.AUTOBUS].includes(props.game.stage) &&
            props.guess,
        [props.game.stage, props.guess]
    );

    /* istanbul ignore next */
    const isSameGuess = useCallback(
        (newGuess: GuessInputType) => isGuessSelected && props.guess === newGuess,
        [isGuessSelected, props.guess]
    );

    /* istanbul ignore next */
    const handleGuessClick = useCallback(
        (newGuess: GuessInputType) => {
            if (isSameGuess(newGuess)) return props.onSubmit();
            props.setGuess(newGuess);
        },
        [isSameGuess, props]
    );

    if (!props.isUsersRound) {
        return <React.Fragment />;
    }

    if (props.aceValueRequired) {
        return <AceValue selectedValue={props.aceValue} setSelectedValue={props.handleAceValue} />;
    }

    if (props.drinksRequired) {
        return (
            <DrinksInput
                selectedGameConnection={props.selectedDrinkingUser}
                setSelectedGameConnection={props.setSelectedDrinkingUser}
                gameConnections={props.game.connections}
            />
        );
    }
    return (
        <section className={styles.header_section}>
            <ul className={styles.input_row}>
                <GuessingInput
                    cards={props.gameConnection.cards}
                    currentGuess={props.guess}
                    setGuess={handleGuessClick}
                    isUsersRound={props.isUsersRound}
                    form={props.form}
                />
            </ul>
        </section>
    );
};

export default GameInput;
