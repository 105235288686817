import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import styles from "./css/Table.module.css";
import Text from "@components/texts";
import { TableRow } from "./types";

interface Props {
    head: string[];
    body: TableRow[];
}

const Table: React.FC<Props> = props => {
    const formatValue = (item: string | boolean) => {
        if (typeof item === "boolean") {
            if (item)
                return (
                    <FontAwesomeIcon
                        color="var(--accent-green)"
                        fontSize="var(--font-size-regular)"
                        icon={faCheckCircle}
                    />
                );
            return (
                <FontAwesomeIcon
                    color="var(--accent-red)"
                    fontSize="var(--font-size-regular)"
                    icon={faCircleXmark}
                />
            );
        }
        return item;
    };

    return (
        <span className={styles.background}>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.head_row}>
                        {props.head.map((head, index) => (
                            <th key={index} className={styles.head_cell}>
                                <Text>{head}</Text>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.body.map((row, index) => (
                        <tr key={index} className={styles.body_row}>
                            {row.cells.map((cell, index) => (
                                <td
                                    key={index}
                                    className={`${styles.body_cell}${
                                        cell.centered ? ` ${styles.body_cell_centered}` : ``
                                    }${cell.fullWidth ? ` ${styles.body_cell_full_width}` : ``}`}
                                >
                                    <Text>{formatValue(cell.display)}</Text>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </span>
    );
};

export default Table;
