import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { LoginInput, useLogin } from "@utils/api";
import { LoginForm } from "@components/forms";
import { Main } from "@components/layout";
import { Heading } from "@components/texts";

const Login: React.FC = () => {
    const form = "login-form";
    const translate = useIntl().formatMessage;
    const navigate = useNavigate();

    const loginMutation = useLogin();

    /* istanbul ignore next */
    const onSubmit = (data: LoginInput) => {
        if (loginMutation.isLoading) return;
        loginMutation.mutate(data, {
            onSuccess: () => navigate("/"),
        });
    };

    return (
        <Main>
            <header>
                <Heading>{translate({ id: "common.login" })}</Heading>
            </header>
            <LoginForm form={form} onSubmit={onSubmit} submitting={loginMutation.isLoading} />
        </Main>
    );
};

export default Login;
