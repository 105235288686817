import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/ArrowBack.module.css";
import sharedStyles from "../css/Shared.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "@components/buttons";

interface Props {
    routeBack: string;
    beforeLeave?: () => void;
}

const ArrowBack: React.FC<Props> = props => {
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        /* istanbul ignore next */
        if (props.beforeLeave) props.beforeLeave();
        navigate(props.routeBack);
    }, [props, navigate]);

    const detectKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.code === "Escape") goBack();
        },
        [goBack]
    );

    useEffect(() => {
        window.addEventListener("keydown", detectKey);
        return () => {
            window.removeEventListener("keydown", detectKey);
        };
    }, [detectKey]);

    return (
        <Button onClick={goBack} className={sharedStyles.shared} name="arrowBack">
            <FontAwesomeIcon icon={faArrowLeft} className={styles.arrow} />
        </Button>
    );
};

export default ArrowBack;
