import { CardSuit } from "@utils/types";

enum GuessColor {
    RED = "RED",
    BLACK = "BLACK",
}

enum GuessValue {
    SMALLER = "SMALLER",
    LARGER = "LARGER",
}

enum GuessInclusive {
    INCLUSIVE = "INCLUSIVE",
    EXCLUSIVE = "EXCLUSIVE",
}

type GuessInputType = GuessColor | GuessValue | GuessInclusive | CardSuit;

export { GuessInputType, GuessColor, GuessValue, GuessInclusive };
