import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { TokenResponseType, User } from "@utils/types";
import { SuccessIcon } from "@components/icons";
import { getCookie } from "@utils/cookies";
import { createUser, getSelf } from "./consumers";
import { CreateUserInput } from "./types";
import { handleAPIError } from "../utils";
import { ErrorResponseData } from "../types";
import { useIntl } from "react-intl";

const useSelf = () => {
    const translate = useIntl().formatMessage;
    const cookiePresent = getCookie("access-token-present");
    return useQuery<User | undefined, AxiosError<ErrorResponseData>>(["self"], getSelf, {
        staleTime: 0,
        enabled: !!cookiePresent,
        onError: async error =>
            handleAPIError(error, translate({ id: "api.users.self.get.error" })),
    });
};

const useRegister = () => {
    const translate = useIntl().formatMessage;
    const queryClient = useQueryClient();
    return useMutation<TokenResponseType, AxiosError<ErrorResponseData>, CreateUserInput>({
        mutationFn: data => createUser(data),
        onSuccess: async response => {
            queryClient.invalidateQueries("self");
            queryClient.refetchQueries("self");
            toast.success(translate({ id: "api.users.register.success" }), { icon: SuccessIcon });
            return response;
        },
        onError: async error =>
            handleAPIError(error, translate({ id: "api.users.register.error" })),
    });
};

export { useSelf, useRegister };
